<template>
  <a-modal
    v-model:visible="isVisible"
    class="cat-modal"
    title="Edit Category Keyword"
    ok-text="Save Change"
    @cancel="close"
    @ok="onOkay"
  >
    <div class="modal-field-title no-top-margin">Type</div>
    <div id="keyword-type-selector" class="form-input">
      <a-select
        v-model:value="editInput.type"
        :getPopupContainer="(trigger) => trigger.parentNode"
        class="type-selector w-100"
        disabled
      >
        <a-select-option value="word">Word or Phrase</a-select-option>
        <a-select-option value="username">Username</a-select-option>
        <a-select-option value="hashtag">Hashtag</a-select-option>
      </a-select>
    </div>
    <div class="modal-field-title">Keyword</div>
    <a-input :defaultValue="record.keyword" disabled />
    <div class="modal-field-title">Sentiment</div>
    <a-dropdown :trigger="['click']" :get-popup-container="(trigger) => trigger.parentNode">
      <a class="sentiment-dropdown" @click.stop="">
        <div class="d-flex align-items-center">
          <FeatherIcon :type="sentimentIcon" size="24" :color="sentimentColor" />
          <span class="ml-1">{{ sentimentText }}</span>
        </div>
        <DownOutlined />
      </a>
      <template #overlay>
        <a-menu @click="onSentimentClick">
          <a-menu-item key="0" value="5">
            <div class="d-flex align-items-center">
              <FeatherIcon type="smile" size="24" color="#20c997" />
              <span class="ml-1">Positive</span>
            </div>
          </a-menu-item>
          <a-menu-item key="1" value="0">
            <div class="d-flex align-items-center">
              <FeatherIcon type="meh" size="24" color="#fa8b0c" />
              <span class="ml-1">Neutral</span>
            </div>
          </a-menu-item>
          <a-menu-item key="2" value="-5">
            <div class="d-flex align-items-center">
              <FeatherIcon type="frown" size="24" color="#ff4d4f" />
              <span class="ml-1">Negative</span>
            </div>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </a-modal>
</template>

<script>
/* eslint-disable no-unused-vars */

import { watch, ref, reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { DownOutlined } from '@ant-design/icons-vue';
import api from '@/services/api';
import helper from '@/services/helper';

export default {
  name: 'KeywordModal',
  props: {
    visible: Boolean,
    record: Object,
    level: Number,
  },
  components: {
    DownOutlined,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { visible, record, level } = toRefs(props);
    const store = useStore();
    const { dispatch } = store;
    const isVisible = ref(visible.value);
    const sentimentValue = ref(5);
    let sentimentIcon = computed(() => {
      if (sentimentValue.value < -1) {
        return 'frown';
      } else if (sentimentValue.value > 1) {
        return 'smile';
      } else {
        return 'meh';
      }
    });
    let sentimentColor = computed(() => {
      if (sentimentValue.value < -1) {
        return '#ff4d4f';
      } else if (sentimentValue.value > 1) {
        return '#20c997';
      } else {
        return '#fa8b0c';
      }
    });
    let sentimentText = computed(() => {
      if (sentimentValue.value < -1) {
        return 'Negative';
      } else if (sentimentValue.value > 1) {
        return 'Positive';
      } else {
        return 'Neutral';
      }
    });

    const editInput = reactive({
      originalKeyword: '',
      keyword: '',
      type: '',
      mode: '',
    });

    watch(
      () => visible.value,
      () => {
        isVisible.value = visible.value;
        if (isVisible.value) {
          sentimentValue.value = record.value.sentiment;
          Object.assign(editInput, {
            originalType: record.value.type,
            originalKeyword: record.value.originalKeyword,
            editingKeyword: record.value.editingKeyword,
            type: record.value.type,
            keyword: record.value.keyword,
          });
        }
      }
    );

    const onSentimentClick = (e) => {
      sentimentValue.value = parseInt(e.item.value);
    };

    const close = (update) => {
      emit('close', update);
    };

    const onOkay = async () => {
      // TODO check for api update word old -> new
      // const { editingKeyword, type } = editInput;
      // let k = editingKeyword;
      // if (type === 'username') k = helper.prepareUsernameWord(editingKeyword);
      // if (type === 'hashtag') k = helper.prepareHashTagWord(editingKeyword);

      const result = await api
        .updateCategoryKeyword(level.value, record.value.originalKeyword, record.value.category, sentimentValue.value)
        .catch((e) => {
          console.log(e);
        });
      if (result) {
        console.log(result);
        await dispatch('config/getAccountCategory');
      }
      close(true);
    };

    return {
      isVisible,
      sentimentValue,
      sentimentIcon,
      sentimentColor,
      sentimentText,
      close,
      onOkay,
      onSentimentClick,
      editInput,
    };
  },
};
</script>

<style lang="scss">
.cat-modal {
  #keyword-type-selector {
    min-width: 200px;
  }

  #keyword-type-selector {
    .ant-select-selector .ant-select-selection-item {
      line-height: 38px !important;
    }
    @media (max-width: 560px) {
      flex: 1 1 auto;
      max-width: 100%;
    }
  }
  .modal-field-title {
    line-height: 24px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 6px;

    &.no-top-margin {
      margin-top: 0;
    }
  }
  .keyword-dropdown,
  .sentiment-dropdown {
    border: 1px solid #e3e6ef;
    padding: 12px;
    border-radius: 4px;
    color: #5a5f7d;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .anticon {
      float: right;
      margin-top: 6px;
    }
  }
}
</style>
