<template>
  <a-modal
    v-model:visible="isVisible"
    class="cat-modal"
    :title="modalTitle"
    :ok-text="modalOk"
    @cancel="close"
    @ok="onOkay"
  >
    <div class="modal-field-title no-top-margin">Category Name</div>
    <a-input v-model:value="newSubcatName" placeholder="Category Name" :disabled="option == 'edit' ? true : false" />
    <div class="modal-field-title">Category Color</div>
    <div class="modal-color-picker">
      <!-- <color-picker :pureColor="choosenColor" disableAlpha disableHistory pickerType="chrome" lang="en" format="hex" @pureColorChange="onChangeColor" /> -->
      <input v-model="choosenColor" type="color" class="picker-color" />
      <input v-model="choosenColor" maxlength="7" class="color-input" />
    </div>
    <div class="modal-field-title">Status</div>
    <a-switch v-model:checked="isEnabled" @change="onChangeStatus" />
  </a-modal>
</template>

<script>
import { watch, ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
// import { ColorPicker } from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import { notification } from 'ant-design-vue';
import chroma from 'chroma-js';

export default {
  name: 'CategoryModal',
  components: {
    // ColorPicker,
  },
  props: {
    visible: Boolean,
    option: String,
    name: String,
    level: Number,
    color: String,
    status: Boolean,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { visible, option, name, level, color, status } = toRefs(props);
    const isVisible = ref(visible.value);
    const store = useStore();
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);
    const { dispatch } = store;
    let isEnabled = ref(false);
    const choosenColor = ref('');
    let newSubcatName = ref('');
    const modalTitle = computed(() => {
      if (option.value == 'edit') {
        return 'Edit Category';
      } else if (option.value == 'add') {
        return 'Add Category';
      } else {
        return '';
      }
    });
    const modalOk = computed(() => {
      if (option.value == 'edit') {
        return 'Save Change';
      } else if (option.value == 'add') {
        return 'Add';
      } else {
        return '';
      }
    });

    // onMounted(() => {
    //   initModal();
    // });
    // watch(
    //   () => visible.value,
    //   () => {
    //     isVisible.value = visible.value;
    //   }
    // );

    watch(visible, () => {
      isVisible.value = visible.value;
      if (isVisible.value) {
        initModal();
      }
    });

    const initModal = () => {
      if (option.value == 'edit') {
        newSubcatName.value = name.value;
        choosenColor.value = color.value;
        isEnabled.value = status.value;
      } else if (option.value == 'add') {
        newSubcatName.value = '';
        choosenColor.value = randomColor();
        isEnabled.value = false;
      }
    };

    const randomColor = () => {
      const h = Math.floor(Math.random() * 360);
      const s = helper.getRandomInt(20, 60);
      const l = helper.getRandomInt(20, 60);
      const colorHex = chroma('hsl(' + h + ',' + s + '%,' + l + '%)').hex();
      return colorHex;
    };

    const onOkay = async () => {
      if (option.value == 'add') {
        const result = await api.addCategory(level.value, newSubcatName.value, choosenColor.value, isEnabled.value).catch((e) => {
          // console.log(e);
          if (e.code === 400) {
            notification.error({
              message: 'Error',
              description: 'Category name is duplicate.',
            });
          }
          notification.error({
            message: 'Error',
            description: 'Category ' + `${newSubcatName.value}` + ' is duplicate.',
          });
        });
        if (isEnabled.value && result) {
          // const visibleResult = await api.addVisibleCategory(level.value, newSubcatName.value).catch((e) => {
          //   console.log(e);
          // });
          const visibleResult = await api
            .editCategory(level.value, newSubcatName.value, choosenColor.value, isEnabled.value)
            .catch((e) => {
              console.log(e);
            });
          if (visibleResult) {
            console.log(visibleResult);
          }
        }
        if (result) {
          await dispatch('config/getAccountCategory');
          if (level.value === campaignLevel.value) {
            await store.dispatch('account/getCampaignMenu');
          }
          // console.log(result);
          notification.success({
            message: 'Success',
            description: 'Category ' + `${newSubcatName.value}` + ' has successfully added',
          });
          close();
        }
      } else if (option.value == 'edit') {
        const result = await api
          .editCategory(level.value, newSubcatName.value, choosenColor.value, isEnabled.value)
          .catch((e) => {
            console.log(e);
          });
        if (result) {
          await dispatch('config/getAccountCategory');
          if (level.value === campaignLevel.value) {
            await store.dispatch('account/getCampaignMenu');
          }
          // console.log(result);
          notification.success({
            message: 'Success',
            description: 'Category ' + `${newSubcatName.value}` + ' updated',
          });
          close();
        }
      }
    };

    const close = () => {
      // clear value
      // newSubcatName.value = '';
      // choosenColor.value = '#000000';
      emit('close');
    };

    const onChangeStatus = (e) => {
      isEnabled.value = e;
    };

    const onChangeColor = (e) => {
      choosenColor.value = e;
    };

    return {
      isVisible,
      isEnabled,
      onOkay,
      close,
      modalTitle,
      modalOk,
      newSubcatName,
      onChangeStatus,
      onChangeColor,
      choosenColor,
    };
  },
};
</script>

<style lang="scss">
.cat-modal {
  .modal-field-title {
    line-height: 24px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 6px;

    &.no-top-margin {
      margin-top: 0;
    }
  }
  .modal-color-picker {
    display: flex;
    align-items: center;
    padding: 12px 10px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
    .vc-color-wrap {
      width: 24px;
    }

    .picker-color {
      border: none;
    }
    .color-input {
      border: none;
      margin-left: 8px;
    }
    .color-input:focus {
      outline: none;
    }
  }
}
.vc-hue-slider__bar[data-v-5c4cae5b] {
  background: -webkit-linear-gradient(
    left,
    red 0%,
    yellow 16.66%,
    lime 33.33%,
    aqua 50%,
    blue 66.66%,
    fuchsia 83.33%,
    red 100%
  );
}
</style>
