<template>
  <a-modal
    v-model:visible="isVisible"
    title="Reorder Category Level"
    class="reorder-modal"
    ok-text="Save"
    @cancel="close()"
    @ok="onOkay"
  >
    <draggable v-model="originalTabs" item-key="id">
      <template #item="{ element }">
        <div class="tabs-list">{{ element.name }}<FeatherIcon type="menu" size="18" /></div>
      </template>
    </draggable>
  </a-modal>
</template>

<script>
import { watch, ref, toRefs, computed } from 'vue';
import draggable from 'vuedraggable';
import api from '@/services/api';
import { useStore } from 'vuex';

export default {
  name: 'ReorderCategoryGroupModal',
  components: {
    draggable,
  },
  props: {
    visible: Boolean,
    tabs: Object,
  },
  // data: function () {
  //   return {
  //     originalTabs: this.tabs,
  //   };
  // },
  // methods: {
  //   refresh() {
  //     this.originalTabs = this.originalTabs.sort((a, b) => a.level - b.level);
  //   },
  // },
  setup(props, { emit }) {
    const store = useStore();
    const { dispatch } = store;
    const { visible, tabs } = toRefs(props);
    const isVisible = ref(visible.value);
    // const instance = getCurrentInstance();
    // const order = computed(() => instance.data.originalTabs.map((a) => a.id));
    let originalTabs = ref();
    const order = computed(() => originalTabs.value.map((a) => a.id));

    const onOkay = async () => {
      const result = await api.changeCategoryGroupOrder(order.value).catch((e) => {
        console.log(e);
      });
      if (result) {
        await dispatch('config/getAccountCategory');
      }
      close();
    };

    // onMounted(() => {
    //   originalTabs.value = tabs.value;
    // });

    watch(
      () => visible.value,
      () => {
        isVisible.value = visible.value;

        if (visible.value) {
          originalTabs.value = tabs.value;
        }
      }
    );

    const close = () => {
      emit('close');
    };

    return {
      onOkay,
      close,
      isVisible,
      originalTabs,
    };
  },
};
</script>
<style lang="scss" scoped>
.tabs-list {
  border: 1px solid #e3e6ef;
  border-radius: 4px;
  padding: 12px 15px;
  margin-bottom: 16px;
  cursor: grab;
  i {
    float: right;
  }
}
</style>
