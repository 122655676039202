<template>
  <div class="subcat">
    <div class="subcat-name">
      <FeatherIcon class="subcat-circle" type="circle" size="14" :fill="color" :color="color" />
      {{ name }}
    </div>
    <div class="switch-more">
      <a-switch v-model:checked="localVisible" @change="onSwitch" />
      <a-dropdown
        :class="name == 'others' ? 'hide' : ''"
        :trigger="['click']"
        placement="bottomRight"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <more-outlined />
        <template #overlay>
          <a-menu>
            <a-menu-item @click="onEditSubcat">
              <FeatherIcon type="edit" size="18" />
              <span style="margin-left: 10px">Edit</span>
            </a-menu-item>
            <a-menu-item @click="onDeleteSubcat">
              <FeatherIcon type="trash-2" size="18" />
              <span style="margin-left: 10px">Delete</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <EditModal
      :visible="editVisible"
      :level="level"
      :name="name"
      :color="color"
      :status="visible"
      option="edit"
      @close="toggleEditModal(false)"
    />
    <a-modal v-model:visible="deleteVisible" class="delete-modal" @ok="confirmDelete" @close="toggleDeleteModal(false)">
      <div class="floating-title-icon"><FeatherIcon type="x-circle" :size="16" /></div>
      <p>Are you sure to delete Category: {{ name }} and all of keywords in this category</p>
    </a-modal>
  </div>
</template>

<script>
import { ref, toRefs, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { MoreOutlined } from '@ant-design/icons-vue';
import EditModal from './CategoryModal.vue';
import api from '@/services/api';

const SubcategoryBlock = {
  components: {
    MoreOutlined,
    EditModal,
  },
  props: {
    name: String,
    color: String,
    level: Number,
    visible: Boolean,
  },
  setup(props) {
    const { visible, color, name, level } = toRefs(props);
    let localVisible = ref(props.visible);
    let editVisible = ref(false);
    let deleteVisible = ref(false);
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);

    const store = useStore();
    const { dispatch } = store;

    const onSwitch = async (e) => {
      const result = await api.editCategory(level.value, name.value, color.value, e).catch((e) => {
        console.log(e);
      });
      if (result) {
        // console.log(result);
        await dispatch('filter/initFilter', true);
      }
    };

    watch(
      () => props.visible,
      () => {
        localVisible.value = visible.value;
      }
    );

    const onEditSubcat = () => {
      editVisible.value = true;
    };
    const toggleEditModal = (state) => {
      editVisible.value = state;
    };
    const toggleDeleteModal = (state) => {
      editVisible.value = state;
    };
    const onDeleteSubcat = () => {
      deleteVisible.value = true;
    };
    const confirmDelete = async () => {
      deleteVisible.value = false;
      const result = await api.deleteCategory(props.level, props.name).catch((e) => {
        console.log(e);
      });
      if (result) {
        await dispatch('config/getAccountCategory');
        if (props.level === campaignLevel.value) {
          await store.dispatch('account/getCampaignMenu');
        }
      }
    };
    const cancelDelete = () => {
      deleteVisible.value = false;
    };

    return {
      visible,
      localVisible,
      onSwitch,
      editVisible,
      deleteVisible,
      onEditSubcat,
      toggleEditModal,
      toggleDeleteModal,
      onDeleteSubcat,
      cancelDelete,
      confirmDelete,
    };
  },
};
export default SubcategoryBlock;
</script>
