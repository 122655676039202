<template>
  <a-modal
    v-model:visible="isVisible"
    class="catgroup-modal"
    :title="modalTitle"
    :ok-text="modalOk"
    @cancel="close"
    @ok="onOkay"
  >
    <div class="modal-field-title no-top-margin">Category Level Name</div>
    <a-input v-model:value="newCatName" placeholder="Category Level Name" />
  </a-modal>
</template>

<script>
import { watch, ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';

export default {
  name: 'CategoryGroupModal',
  props: {
    visible: Boolean,
    option: String,
    level: Number,
    name: String,
  },
  setup(props, { emit }) {
    const { visible, option, level, name } = toRefs(props);
    const store = useStore();
    const { dispatch } = store;
    const isVisible = ref(visible.value);
    let newCatName = ref('');
    const modalTitle = computed(() => {
      if (option.value == 'rename') {
        return 'Rename Category Level';
      } else if (option.value == 'add') {
        return 'Add Category Level';
      } else {
        return '';
      }
    });

    const modalOk = computed(() => {
      if (option.value == 'rename') {
        return 'Save Changes';
      } else if (option.value == 'add') {
        return 'Add';
      } else {
        return '';
      }
    });

    const onOkay = async () => {
      if (option.value == 'rename') {
        const result = await api.renameCategoryGroup(level.value, newCatName.value).catch((e) => {
          console.log(e);
        });
        if (result) {
          await dispatch('config/getAccountCategory');
          // console.log(result);
        }
      } else if (option.value == 'add') {
        const result = await api.addCategoryGroup(newCatName.value).catch((e) => {
          console.log(e);
        });
        if (result) {
          await dispatch('config/getAccountCategory');
          // console.log(result);
        }
      }
      close();
    };

    // watch(
    //   () => visible.value,
    //   () => {
    //     isVisible.value = visible.value;
    //   }
    // );

    watch(visible, () => {
      isVisible.value = visible.value;
      if (isVisible.value) {
        initData();
      }
    });

    const initData = () => {
      newCatName.value = name.value;
    };

    const close = () => {
      newCatName.value = '';
      emit('close');
    };

    return {
      modalOk,
      onOkay,
      close,
      isVisible,
      modalTitle,
      newCatName,
    };
  },
};
</script>

<style lang="scss" scoped>
.catgroup-modal {
  .modal-field-title {
    line-height: 24px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 6px;

    &.no-top-margin {
      margin-top: 0;
    }
  }
}
</style>
